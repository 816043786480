import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../../layout/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero"
import CTA from "../../components/cta"
import PageWrapper from "../../components/page-wrapper"
import IconDescription from "../../components/icon-description"
import CardVertical from "../../components/card-vertical"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Spacer from "../../components/spacer"
import content from "../../locales/en/products-derogel.json"
import SpecificationsGrid from "../../components/specification-grid"
import Button from "../../components/button-borderless-arrow"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
  },
  bannerContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  imgBanner: {
    height: "150px",
    width: "312px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "120px",
  },
  header: {
    width: "70%",
    marginTop: "36px",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
    },
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  gridContainer: {
    marginTop: "48px",
    marginBottom: "24px",
    "@media (max-width:768px)": {
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginEnd: "32px",
  },
})

export default function Derogel({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    query ProductsDerogelContent {
      heroImage: file(relativePath: { eq: "derogel/derogel_hero_image.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productBanner: file(
        relativePath: { eq: "derogel/derogel-banner_1@4x.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      featureImage1: file(
        relativePath: { eq: "derogel/derogel_feature_1.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      featureImage2: file(
        relativePath: { eq: "derogel/derogel_feature_2.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      featureImage3: file(
        relativePath: { eq: "derogel/derogel_feature_3.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroImage1: file(
        relativePath: { eq: "derogel/derogel_product_variations.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroImage2: file(
        relativePath: { eq: "derogel/derogel_versatile_roll.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cardImage1: file(relativePath: { eq: "derogel/derogel_clothing.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cardImage2: file(relativePath: { eq: "derogel/derogel_footwear.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cardImage3: file(relativePath: { eq: "derogel/derogel_gloves.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      datasheet: file(relativePath: { eq: "Derogel_datasheet.pdf" }) {
        publicURL
      }
    }
  `)

  const heroImage = data.heroImage.childImageSharp.fluid
  const featureImage1 = data.featureImage1.childImageSharp.fluid
  const featureImage2 = data.featureImage2.childImageSharp.fluid
  const featureImage3 = data.featureImage3.childImageSharp.fluid
  const productBanner = data.productBanner.childImageSharp.fluid

  const heroImage1 = data.heroImage1.childImageSharp.fluid
  const heroImage2 = data.heroImage2.childImageSharp.fluid

  const cardImage1 = data.cardImage1.childImageSharp.fluid
  const cardImage2 = data.cardImage2.childImageSharp.fluid
  const cardImage3 = data.cardImage3.childImageSharp.fluid

  content.sectionKeyFeatures.cards[0].imageData = featureImage1
  content.sectionKeyFeatures.cards[1].imageData = featureImage2
  content.sectionKeyFeatures.cards[2].imageData = featureImage3

  content.sectionApplications.cards[0].imageData = cardImage1
  content.sectionApplications.cards[1].imageData = cardImage2
  content.sectionApplications.cards[2].imageData = cardImage3

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = crumbs[crumbs.length - 1].crumbLabel.replace(
    new RegExp("-", "g"),
    " "
  )
  const classes = useStyles()

  return (
    <>
      <Layout location={location}>
      <SEO title={content.seo.title} />
        <Hero
          imageData={heroImage}
          textTitle={content.hero.heading}
          textDescription={content.hero.description}
          dark={true}
        />
        <PageWrapper>
          <div className={classes.root}>
            <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />
            <div className={classes.bannerContainer}>
              <Img className={classes.imgBanner} fluid={productBanner} />
            </div>
            <div className={classes.headingContainer}>
              <Typography className={classes.header} variant="h2">
                {content.sectionKeyFeatures.heading}
              </Typography>
            </div>
            <Grid
              className={classes.gridContainer}
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {content.sectionKeyFeatures.cards.map((card, index) => (
                <Grid key={index} item xs={12} sm={3}>
                  <IconDescription
                    description={card.heading}
                    imageData={card.imageData}
                    imgAlt={card.imgAlt}
                  />
                </Grid>
              ))}
            </Grid>
            <Hero
              imageData={heroImage1}
              textTitle={content.sectionHero1.heading}
              textDescription={content.sectionHero1.description}
            />
            <Spacer />
            <Hero
              imageData={heroImage2}
              textTitle={content.sectionHero2.heading}
              textDescription={content.sectionHero2.description}
            />
            <div className={classes.headingContainer}>
              <Typography className={classes.header} variant="h2">
                {content.sectionApplications.heading}
              </Typography>
            </div>
            <Grid
              className={classes.gridContainer}
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {content.sectionApplications.cards.map((card, index) => (
                <Grid key={index} item xs={12} sm={4}>
                  <CardVertical
                    title={card.heading}
                    description={card.description}
                    imageData={card.imageData}
                    imgAlt={card.imgAlt}
                  />
                </Grid>
              ))}
            </Grid>
            <div className={classes.headingContainer}>
              <Typography className={classes.header} variant="h2">
                {content.sectionKeyProperties.heading}
              </Typography>
            </div>
            <div className={classes.gridContainer}>
              <SpecificationsGrid
                columns={content.sectionKeyProperties.cards}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                onClickUrl={data.datasheet.publicURL}
                label={"Download Data Sheet"}
              />
            </div>
            <Spacer />
          </div>
        </PageWrapper>
        <CTA />
      </Layout>
    </>
  )
}
